@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    /* Fonts */
    @font-face {
        font-family: 'publico_headline_webbold';
        src: url('/public/font/publico_headline_web_bold_regular-webfont.woff2') format('woff2'),
            url('/public/font/publico_headline_web_bold_regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;

    }


    @font-face {
        font-family: 'source_sans_problack';
        src: url('/public/font/sourcesanspro-black-webfont.woff2') format('woff2'),
            url('/public/font/sourcesanspro-black-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;

    }


    @font-face {
        font-family: 'source_sans_proregular';
        src: url('/public/font/sourcesanspro-regular-webfont.woff2') format('woff2'),
            url('/public/font/sourcesanspro-regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;

    }
     
    
    .gradiant-card-bg {
        background: linear-gradient(51.47deg, rgba(255, 113, 113, 0.3) 0.59%, rgba(17, 149, 30, 0.072) 42.57%, rgba(36, 83, 205, 0) 48.87%, rgba(1, 8, 26, 0.3) 90.33%);
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    input[type="radio"] {
        /* remove standard background appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .triangle-down {
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 24px solid #323242;
        border-radius: 4px;
    }
    .triangle-down-small {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 20px solid #323242;
        border-radius: 4px;
    }

}

@layer components {
    .custom-radio-btn {
        @apply border h-3 w-3 rounded-full border-range-bg checked:bg-dark-yellow cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .custom-radio-btn-black {
        @apply border h-3 w-3 rounded-full border-range-bg checked:bg-extra-dark-gray cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .custom-radio-btn-blue {
        @apply border h-3 w-3 rounded-full border-range-bg checked:bg-btn-blue cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .custom-radio-btn-orange {
        @apply border h-3 w-3 rounded-full border-range-bg checked:bg-btn-orange cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .custom-checkbox {
        @apply border h-3 w-3 rounded-sm border-range-bg accent-dark-yellow cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .custom-checkbox-black {
        @apply border h-3 w-3 rounded-sm border-range-bg accent-black cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .custom-checkbox-blue {
        @apply border h-3 w-3 rounded-sm border-range-bg accent-btn-blue cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .custom-checkbox-orange {
        @apply border h-3 w-3 rounded-sm border-range-bg accent-btn-orange cursor-pointer min-w-[.75rem] disabled:cursor-default
    }
    .yellow-bg-btn {
        @apply text-sm font-semibold text-extra-dark-gray px-7 py-2 rounded-lg bg-dark-yellow hover:bg-opacity-90 disabled:cursor-default
    }
    .blue-bg-btn {
        @apply text-sm font-semibold text-white px-7 py-2 rounded-lg bg-btn-blue hover:bg-opacity-90 disabled:cursor-default
    }
    .orange-bg-btn {
        @apply text-sm font-semibold text-white px-7 py-2  bg-btn-orange hover:bg-btn-orange-dark disabled:cursor-default
    }
    .yellow-border-btn {
        @apply text-sm font-semibold text-strong-gray px-7 py-2 rounded-lg bg-transparent hover:bg-dark-yellow hover:text-white border border-dark-yellow disabled:cursor-default
    }
    .black-border-btn {
        @apply text-sm font-semibold text-strong-gray bg-transparent border-strong-gray hover:bg-strong-gray hover:text-white px-7 py-2 rounded-lg border disabled:cursor-default
    }
    .blue-border-btn {
        @apply text-sm font-semibold text-btn-blue bg-transparent border-btn-blue hover:bg-btn-blue hover:text-white px-7 py-2 rounded-lg border disabled:cursor-default
    }
    .orange-border-btn {
        @apply text-sm font-semibold text-btn-orange bg-transparent border-btn-orange hover:bg-btn-orange-dark hover:text-white px-7 py-2  border disabled:cursor-default
    }
    .redTxt {
        @apply text-lite-red hover:text-red-900/80 transform duration-300 font-semibold text-sm
    }
    .passed {
        @apply text-success font-semibold
    }
    .failed {
        @apply text-err-input-border font-semibold
    }
}
#s-sv-bn{display: none;}
#c-s-in{height: auto !important;}
.invalid-recaptcha>div>div{border: 1px solid red;border-radius: 5px;}